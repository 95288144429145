@tailwind base;
@tailwind components;
@tailwind utilities;


/* index.css */
ul li a {
    color: #000;
}

ul li a:hover {
    color: #00a8ff;
}

ul li a.active {
    color: #00a8ff;
}